import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import Img from "gatsby-image";

const intro = ({
  data: { calmImage, sleepingImage, prayingImage, easyImage },
}) => {
  const renderCard = (title, content, image) => {
    return (
      <div
        className="has-text-centered columns"
        style={{ maxWidth: "90%", margin: "auto", paddingTop: "5rem" }}
      >
        <Img
          fluid={image}
          className="image column"
          style={{ borderRadius: "25px" }}
        />
        <div className="column">
          <h2
            className="title"
            style={{ marginBottom: "0.5rem", marginTop: "2rem" }}
          >
            {title}
          </h2>
          <p className="content">{content}</p>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <h1
        className="is-size-1 has-text-centered  has-text-weight-bold"
        style={{ marginBottom: "0rem", marginTop: "2rem" }}
      >
        What is calm<span className="has-text-success">dog</span>?
      </h1>
      <div className="container" style={{ marginBottom: "5rem" }}>
        {renderCard(
          "It's just mat training",
          "Teach your dog to calmy lay on their mat, no matter what is going on around them",
          sleepingImage.childImageSharp.fluid
        )}
        {renderCard(
          "Your dog learns to calm themselves",
          "After a few days of practice your dog will be less anxious and able to calm themselves down",
          easyImage.childImageSharp.fluid
        )}
        {renderCard(
          "Enjoy a calmer dog",
          "Calm dog will help reduce your dogs general anxiety and gives you a tool(the mat) to help them calm down in very high stress situations",
          calmImage.childImageSharp.fluid
        )}
        {renderCard(
          "It's easy!",
          "All you need is a mat, some delicious treats and your dog.",
          prayingImage.childImageSharp.fluid
        )}
        <div
          style={{ margin: "auto", padding: "4rem 1rem" }}
          className="columns has-text-centered"
        >
          <Link
            style={{ maxWidth: "200px", margin: "auto" }}
            to="/relaxation/"
            className="button is-success is-medium is-rounded column"
          >
            Try It!
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default intro;

export const query = graphql`
  {
    calmImage: file(name: { eq: "dog_calm" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sleepingImage: file(name: { eq: "dog_laying_mat_1" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prayingImage: file(name: { eq: "dog_praying_1" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    easyImage: file(name: { eq: "dog_sleeping_1" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
